import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { firstValueFrom } from 'rxjs'

/**
 * Runtime configuration interface.
 * It contains all the properties that are loaded from the config.json file and
 * that could change depending on the env where the application is deployed.
 */
export interface RuntimeConfig {
   environment: string
   apiUrl: string
   pmpEditUrl: string
   pmpDetailUrl: string
   pmpIpUrl: string
   pmpIrUrl: string
}

/**
 * Service that provides the runtime configuration of the application.
 * It loads the configuration from a remote endpoint and provides it to the application.
 */
@Injectable({
   providedIn: 'root',
})
export class ConfigService {
   private readonly RUNTIME_CONFIG_PATH = '/assets/configs/properties.json'

   private config: RuntimeConfig = {
      environment: 'NONE',
      apiUrl: '',
      pmpEditUrl: '',
      pmpDetailUrl: '',
      pmpIpUrl: '',
      pmpIrUrl: '',
   }

   constructor(private readonly httpClient: HttpClient) {}

   /**
    * Initializes the dynamic configuration from the config.json file.
    * @returns {Promise<RuntimeConfig>} The dynamic configuration.
    */
   initRuntimeConfig(): Promise<RuntimeConfig> {
      return firstValueFrom(this.httpClient.get<RuntimeConfig>(this.RUNTIME_CONFIG_PATH)).then((dc) => {
         if (!dc) {
            throw new Error('Runtime configuration not found')
         }
         this.config = dc
         console.log('Runtime Configuration loaded for env: ', this.environment)
         return dc
      })
   }

   /**
    * @returns {string} The current environment from the dynamic configuration. Expected values are NONE | LOCAL | DEV | TEST | PROD
    */
   get environment(): string {
      return this.config.environment
   }

   /**
    * Gets the API URL from the runtime configuration.
    *
    * @return {string} The API URL as specified in the runtime configuration.
    */
   get old_BE_ApiUrl(): string {
      return this.config.apiUrl + '/1.1'
   }

   get new_BE_ApiUrl(): string {
      return this.config.apiUrl + '/2.1'
   }

   get pmpEditUrl(): string {
      return this.config.pmpEditUrl
   }

   get pmpDetailUrl(): string {
      return this.config.pmpDetailUrl
   }

   get pmpIpUrl(): string {
      return this.config.pmpIpUrl
   }

   get pmpIrUrl(): string {
      return this.config.pmpIrUrl
   }
}
